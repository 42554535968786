const essayInfo = [ 
    {
        "url": "/documents/RyanHenaoFinalPaperCreativityAndEnvironmentRevised.pdf",
        "title": "The Creative and Environmental Process: A Divine Collaboration",
        "date": "20230302"
    },
    {
        "url": "/documents/TheoryOfTranspersonalityEssayRyanHenao.pdf",
        "title": "The Journey Home: A Theory of Personality",
        "date": "20221214"
    },
    {
        "url": "/documents/CrossCulturalExplorationRyanHenao.pdf",
        "title": "Chaos, Renewal, and The Hero: A Cross Cultural Exploration",
        "date": "20220904"
    },
    {
        "url": "/documents/NatureProgramPaperRyanHenao.pdf",
        "title": "LA Eco-Homes: A Program Proposal",
        "date": "20220610"
    },
    {
        "url": "/documents/UrbanPermaculturePaperRyanHenao.pdf",
        "title": "Urban Permaculture",
        "date": "20220315"
    },
    {
        "url": "/documents/CreativeApplicationRyanHenao.pdf",
        "title": "Myths, Symbols and Art: Connecting to Our Inner Mythology Through Creative Expression",
        "date": "20220228"
    }
];

export default essayInfo;