import React, { Component } from 'react';

class ArtGroups extends Component {
    handleImageClick = () => {
        const document_file_path = "path/to/your/document.pdf"; // Replace with the actual document path
        window.open(document_file_path, '_blank', 'noopener,noreferrer');
    }
    render () {
        let file_path = "/images/art/groups/";
        let image_file = "IMG_4597.jpg";
        let image_file_path = file_path + image_file;
        return (
            <div className="text-section workshop-text-section">
                <img src={image_file_path} alt="" id="workshop_image" className="workshops-art-group-image" ></img>
                <p>
                    During our arts and garden groups, we engage in various
                    holistic activities such as making chalk art, growing
                    vegetables like corn, carrots, and tomatoes, painting rocks,
                    and learning sustainable practices like making newspaper
                    pots. We go on outings to pick up local resources like free
                    compost and mulch. We also spend time in the garden learning
                    about the various plants. In learning about nature,
                    exercising their creativity, and working together, the
                    clients gain confidence in themselves and their autonomy,
                    using the power of imagination and connection to begin to
                    heal and change.
                </p>
                <div style={{clear: "both"}}></div>
            </div>
        );
    }
}

export default ArtGroups 