import React, { Component } from 'react';

class ContactHeader extends Component {
    render() {
        return (
            <div className="more-page-header booklist-header">
                <h6>Contact Information</h6>
            </div>
        );
    }
}

export default ContactHeader;