import React from 'react';

const BooklistSection = ({section, sectionIndex}) => {

    const handleBookClick = (book) => {
        if ("link" in book) window.open(book.link, '_blank');
        return;
    };

    return (
        <ol className="more-page-body book-section-list">
            {section.books.map((book, bookIndex) =>
                <li className={`${("img" in book) ? 'book-section-entry default-link' : 'book-section-entry-no-image'}`} key={bookIndex} onClick={() => handleBookClick(book)}>
                    {"img" in book &&
                        <img src={book.img} alt=""></img>
                    }
                    <div className="book-info">
                        <span className="book-info-title">{book.title}</span>{"img" in book && <br />}
                        <small className="book-info-details">
                            {book.author}
                            {"date" in book && <span> - {book.date}</span>}
                            {"notes" in book && <span><br /><a href={book.notes} id="notes">Notes</a></span>}
                        </small>
                    </div>
                </li>
            )}
        </ol>
    );
};

export default BooklistSection;