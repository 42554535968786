import React, { useState, useEffect } from 'react';
import ModalImage from '../ModalImage/ModalImage';
import usePlaceholderImageLoader from '../../Utils/usePlaceholderImageLoader';
import useLazyLoadPlaceholderImage from '../../Utils/useLazyLoadPlaceholderImage';

const MasonryImage = ({ image, rowIndex, colIndex }) => {
    const [src, imgRef] = useLazyLoadPlaceholderImage(image.src, image.width, image.height);
    
    return (
        <ModalImage 
            key={((rowIndex * image.rowLength) + colIndex)}
            src={src}
            alt={image.alt}
            style={{ 
                position: 'absolute',
                width: `${image.width}px`, 
                height: `${image.height}px`, 
                top: `${image.top}px`, 
                left: `${image.left}px`
            }}
            ref={imgRef}
        >
        {image.dataset.description ?  
            <p className="modal-content-description">{image.dataset.description}</p> : null 
        }
        </ModalImage>
    );
};

export default MasonryImage;
