const randomSortOrder = () => Math.random() - 0.5;

export const image_path = "/images/art/groups/";

export const images = [
    { 'image': 'IMG_4636.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 4032, naturalHeight: 3024},
    { 'image': 'IMG_4831.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 2667, naturalHeight: 3538},
    { 'image': 'IMG_4834.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 1892, naturalHeight: 3284},
    { 'image': 'IMG_4835.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 2737, naturalHeight: 3900},
    { 'image': 'IMG_4836.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 2292, naturalHeight: 3257},
    { 'image': 'IMG_4814.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 3024, naturalHeight: 4032},
    { 'image': 'IMG_4887.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 3004, naturalHeight: 2403},
    //{ 'image': 'IMG_4832.jpg', 'description': '', 'alt': ''},
    //{ 'image': 'IMG_4598.jpg', 'description': '', 'alt': ''},
];

// Function to add 'src' property to each image object
export const imagesWithSrc = (() => {
    return images.map(image => ({
        ...image,
        src: `${image_path}${image.image}`
    }));
})(images);

export const imagesRandomized = [...imagesWithSrc].sort(randomSortOrder);

export default images;