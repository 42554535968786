import _ from 'lodash';

// utility funcions 

const utils = {
    any: (list, key, match) => _.findIndex(list, (item) => item[key] === match) !== -1, // any :: ([Obj], String, Value) -> Boolean
    notAny: (list, key, match) => !utils.any(list, key, match), // notAny :: ([Obj], String, Value) -> Boolean
    safeDivide: (dividend, divisor) => _.isFinite(dividend) && _.isFinite(divisor) ? (dividend / divisor || 0) : 0,
    roundedFloat: (value, precision = 2) => parseFloat(value.toFixed(precision)), // roundedFloat :: (Number, Number) -> Float
    transposed: (grid) => _.zip(...grid), // transposed :: ([[Object]]) -> [[Object]]
    flatten: (grid) => _.flatten(grid), // flatten :: ([[Object]]) -> [Object]
    clean: (grid) => _.dropRightWhile(grid, _.isEmpty), // clean :: ([[Object]]) -> [[Object]]
    flattenedCleaned: (grid) => _.flow([utils.flatten, utils.clean])(grid) // flattenCleaned :: ([[Object]]) -> [Object]
};

export default utils;