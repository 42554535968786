import React, { Component, useRef } from 'react';
import VideoHeader from './VideoHeader.js';
import VideosContent from './VideosContent.js';

class Videos extends Component {
    render () {
        return (
            <div className="videos-page">
                <VideoHeader />
                <VideosContent/>
            </div>
        );
    }
}

export default Videos;