import React, { useState, useEffect } from 'react';
import GridBuilder from './GridBuilder';
import ModalImage from '../ModalImage/ModalImage';

import useImageLoader from '../../Utils/useImageLoader';
import MasonryImage from './MasonryImage';

// ================================================================================= 
// Component: MasonryGrid
// Description: Creates a MasonryGrid of images
// ================================================================================= 

const MasonryGrid = (props) => {
    const { images, numberOfColumns, gridWidth } = props;

    const gridBuilder = GridBuilder(numberOfColumns, gridWidth); // creates and stores 2d representation of masonry grid to be rendered
    const grid = gridBuilder.build(images);

    const containerStyle = { width: `${gridWidth}px`, height: `${gridBuilder.gridHeight(grid)}px`}

    return (
        <div className="masonry-gallery-grid-container" style={containerStyle}>
            <div className="masonry-gallery-grid" style={containerStyle}>
                {grid.map((row, rowIndex) => (
                    row.map((image, colIndex) => (
                        <MasonryImage 
                            key={((rowIndex * row.length) + colIndex)}
                            image={{ ...image, rowLength: row.length }}
                            rowIndex={rowIndex}
                            colIndex={colIndex}
                        />
                    ))
                ))}
            </div>
        </div>
    );
};

export default MasonryGrid;