import React, { Component } from 'react';

class PersonalGalleryHeader extends Component {

    render () {
        return (
            <div className="more-page-header personal-gallery-header">
                <h6>Art from my personal gallery.</h6>
            </div>
        );
    }
}

export default PersonalGalleryHeader