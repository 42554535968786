import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import LazyLoadComponent from '../../Apps/LazyComponent/LazyLoadComponent.js';
import More from './FooterLinks.js';

class Footer extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <div id={this.props.id} className={this.props.className}>
        <div className="page footer-page"> 
          <div className="footer-content">
            <More/>
            <NavLink className="link-size default-link footer-content-site-name">ryanbhenao.com 2023</NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;