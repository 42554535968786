import React, { Component } from 'react';
import ResizeableMasonryGridMasonryGrid from '../../Apps/MasonryGrid/ResizeableMasonryGrid.js';
import { imagesRandomized as workshopImages } from '../../../data/workshopimages.js';
import { imagesRandomized as groupImages } from '../../../data/groupimages.js';
import { imagesRandomized as gardenImages } from '../../../data/gardenimages.js';

class GalleryGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workshopImages: workshopImages,
      groupImages: groupImages,
      gardenImages: gardenImages,
      numberOfColumns: 4,
      responsiveColConfig: [
        { label: 'small', threshold: 800, numberOfColumns: 2},
        { label: 'medium', threshold: 1024, numberOfColumns: 3},
        { label: 'large', threshold: Number.MAX_SAFE_INTEGER, numberOfColumns: 4}
      ],
      responsiveGridWidth: [
        { label: 'small', threshold: 800, percentage: 0.96},
        { label: 'medium', threshold: 1024, percentage: 0.90},
        { label: 'large', threshold: Number.MAX_SAFE_INTEGER, percentage: 0.80}
      ],
      gridWidth: 1800,
    };
  }

  render() {
    return (
        <div className="workshop-masonry-gallery-container"> 
            <h4 className="gallery-h4-groups">Arts Groups</h4>
            <ResizeableMasonryGridMasonryGrid images={this.state.groupImages} responsiveColConfig={this.state.responsiveColConfig} responsiveGridWidth={this.state.responsiveGridWidth}/> 
            <h4 className="gallery-h4-garden">Garden Groups</h4>
            <ResizeableMasonryGridMasonryGrid images={this.state.gardenImages} responsiveColConfig={this.state.responsiveColConfig} responsiveGridWidth={this.state.responsiveGridWidth}/>
            <h4 className="gallery-h4-workshops">Workshops</h4>
            <ResizeableMasonryGridMasonryGrid images={this.state.workshopImages} responsiveColConfig={this.state.responsiveColConfig} responsiveGridWidth={this.state.responsiveGridWidth}/>
        </div>
    );
  }
}

export default GalleryGrid;