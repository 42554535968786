const books = [
    {
        "section": "Transpersonal Psychology",
        "books": [
            {
                "title": "The Wiley Blackwell Handbook of Transpersonal Psychology",
                "author": "Harris Friedman, Glenn Hartelius",
                "date": "2013",
                "link": "https://amzn.to/3Lcwmnm",
                "img": "/images/booklist/theWileyBlackwellHandbookOfTranspersonalPsychology.jpg"

            },
            {
                "title": "The Adventure of Self-Discovery",
                "author": "Stanislav Grof",
                "date": "1988",
                "link": "https://amzn.to/3DofyYT",
                "img": "/images/booklist/theAdventureOfSelfDiscovery.jpg"
            },
            {
                "title": "The Voice of the Earth: An Exploration of Ecopsychology",
                "author": "Theodore Roszak",
                "date": "1992",
                "notes_not_ready": "/documents/theVoiceOfTheNewEarthBooknotes.pdf",
                "link": "https://amzn.to/3qUtjqR",
                "img": "/images/booklist/theVoiceOfTheEarth.jpg"
            },
            {
                "title": "The Archetype of Initiation",
                "author": "Robert L. Moore",
                "date": "2001",
                "notes_not_ready": "/documents/theArchetypeOfInitiationBooknotes.pdf",
                "link": "https://amzn.to/3Dkkmyk",
                "img": "/images/booklist/theArchetypeOfInitiation.jpg"
            },
            {
                "title": "The Finders",
                "author": "Jeffery A Martin",
                "date": "2006",
                "link": "https://amzn.to/3LeEaF3",
                "img": "/images/booklist/theFinders.jpg"
            },
            {
                "title": "The Creative Connection",
                "author": "Natalie Rogers",
                "date": "1993",
                "link": "https://amzn.to/3RKBmSA",
                "img": "/images/booklist/theCreativeConnection.jpg"
            },
            {
                "title": "Modern Psychology and Ancient Wisdom",
                "author": "Sharon Mijares",
                "date": "2016",
                "link": "https://amzn.to/3Dkug2P",
                "img": "/images/booklist/modernPsychologyAndAncientWisdom.jpg"
            },
            {
                "title": "Soulcraft: Crossing into the Mysteries of Naature and the Psyche",
                "author": "Bill Plotkin",
                "date": "2003",
                "notes_not_ready": "/documents/soulcraftBooknotes.pdf",
                "link": "https://amzn.to/3eCXKyE",
                "img": "/images/booklist/soulcraft.jpg"
            },
            {
                "title": "Entangled Minds",
                "author": "Dean Radin",
                "date": "2006",
                "link": "https://amzn.to/3eJUKka",
                "img": "/images/booklist/entangledMinds.jpg"
            },
            {
                "title": "Journeys Out of The Body: The Classic Work on Out-of-Body Experience",
                "author": "Robert Monroe",
                "date": "1992",
                "link": "https://amzn.to/3Dtc2MV",
                "img": "/images/booklist/journeysOutOfTheBody.jpg"
            }
        ],
    },
    {"section": "Consciousness and Metaphysics",
        "books": [
            {
                "title": "What is Reality? ",
                "author": "Ervin Laszlo",
                "date": "2016",
                "link": "https://amzn.to/3RGgJHe",
                "img": "/images/booklist/whatIsReality.jpeg"
            },
            {
                "title": "The Case Against Reality",
                "author": "Donald Hoffman",
                "date": "2019",
                "link": "https://amzn.to/3U85A3H",
                "img": "/images/booklist/caseAgainstReality.jpeg"
            },
            {
                "title": "Wholeness and the Implicate Order",
                "author": "David Bohm",
                "date": "2002",
                "link": "https://amzn.to/3dcFfkl",
                "img": "/images/booklist/wholenessAndImplicateOrder.jpg"
            },
            {
                "title": "I Am A Strange Loop",
                "author": "Douglas Hofstadter",
                "date": "2008",
                "link": "https://amzn.to/3qCHO2e",
                "img": "/images/booklist/IAmAStrangeLoop.jpg"
            },
            {
                "title": "Why Materialism is Baloney",
                "author": "Bernardo Kastrup",
                "date": "2014",
                "link": "https://amzn.to/3ddRLQG",
                "img": "/images/booklist/whyMaterialismIsBaloney.jpg"
            },
            {
                "title": "The Holographic Universe",
                "author": "Michael Talbot",
                "date": "1991",
                "link": "https://amzn.to/3QBEhMh",
                "img": "/images/booklist/theHolographicUniverse.jpeg"
            },
            {
                "title": "The Universe is a Dream: The Secrets of Existence Revealed",
                "author": "Alexander Manchard",
                "date": "2011",
                "link": "https://amzn.to/3LedYKX",
                "img": "/images/booklist/theUniverseIsADream.jpeg"
            },
            {
                "title": "Decoding Jungs Metaphysics: The Archetypal Semantics of an Experiental Universe",
                "author": "Bernardo Kastrup",
                "date": "2019",
                "link": "https://amzn.to/3B6DEEE",
                "img": "/images/booklist/decodingJungsMetaphysics.jpeg"
            },
            {
                "title": "The Science Delusion",
                "author": "Rupert Sheldrake",
                "date": "2012",
                "link": "https://amzn.to/3S4Ncqm",
                "img": "/images/booklist/theScienceDelusion.jpg"
            },
            {
                "title": "The Book of Not Knowing",
                "author": "Peter Ralston",
                "date": "2010",
                "link": "https://amzn.to/3RK9wpN",
                "img": "/images/booklist/theBookOfNotKnowing.jpg"
            },
            {
                "title": "Mind and Cosmos",
                "author": "Thomas Nagel",
                "date": "2012",
                "link": "https://amzn.to/3S3k3Mi",
                "img": "/images/booklist/mindAndCosmos.jpg"
            },
            {
                "title": "The Emperor's New Mind",
                "author": "Roger Penrose",
                "date": "1999",
                "link": "https://amzn.to/3S4Z6kq",
                "img": "/images/booklist/theEmperorsNewMind.jpg"
            }

        ]
    },
    {
        "section": "Mythology and Symbols",
        "books": [
            {
                "title": "The Forge and the Crucible",
                "author": "Mircea Eliade",
                "date": "1979",
                "notes": "/documents/theForgeAndTheCrucibleBooknotes.pdf",
                "link": "https://amzn.to/3xiHdXa",
                "img": "/images/booklist/theForgeAndTheCrucible.jpg"
            },
            {
                "title": "More Than Allegory",
                "author": "Bernardo Kastrup",
                "date": "2012",
                "notes_not_ready": "/documents/moreThanAllegoryBooknotes.pdf",
                "link": "https://amzn.to/3xio47B",
                "img": "/images/booklist/moreThanAllegory.jpeg"
            },
            {
                "title": "The Secret Teachings of All Ages",
                "author": "Manly P. Hall",
                "date": "2010",
                "link": "https://amzn.to/3debAHy",
                "img": "/images/booklist/theSecretTeachingsOfAllAges.jpg"
            },
            {
                "title": "The Power Of Myth",
                "author": "Joseph Campbell",
                "date": "1991",
                "link": "https://amzn.to/3QHALzX",
                "img": "/images/booklist/thePowerOfMyth.jpg"
            },
            {
                "title": "The Hero with a Thousand Faces",
                "author": "Joseph Campbell",
                "date": "2008",
                "notes_not_ready": "/documents/theHeroWithAThousandFacesBooknotes.pdf",
                "link": "https://amzn.to/3S3keqW",
                "img": "/images/booklist/theHeroWithAThousandFaces.jpg"
            },
            {
                "title": "Myths from Mesopotamia",
                "author": "Stephanie Dalley",
                "date": "2008",
                "notes_not_ready": "/documents/mythsFromMesopotamiaBooknotes.pdf",
                "link": "https://a.co/d/iS3Mixk",
                "img": "/images/booklist/mythsFromMesopotamia.jpg"
            }
        ]
    },
    {
        "section": "Spirituality",
        "books": [
            {
                "title": "Power Vs Force",
                "author": "David R. Hawkins",
                "date": "1994",
                "link": "https://amzn.to/3BECn9i",
                "img": "/images/booklist/powerVsForce.jpg"
            },
            {
                "title": "The Eye of I",
                "author": "David R. Hawkins",
                "date": "2016",
                "link": "https://amzn.to/3BAOcgK",
                "img": "/images/booklist/theEyeOfI.jpg"
            },
            {
                "title": "Letting Go: The Pathway of Surrender",
                "author": "David R. Hawkins",
                "date": "2012",
                "link": "https://amzn.to/3UaCXTg",
                "img": "/images/booklist/lettingGo.jpeg"
            },
            {
                "title": "Healing and Recovery",
                "author": "David R. Hawkins",
                "date": "2020",
                "link": "https://amzn.to/3QFABZF",
                "img": "/images/booklist/healingAndRecovery.jpeg"
            },
            {
                "title": "Conversations With God",
                "author": "Neale Donald Walsch",
                "date": "2005",
                "link": "https://amzn.to/3RWNpfe",
                "img": "/images/booklist/conversationsWithGod.jpeg"
            },
            {
                "title": "Connecting with Coincidence",
                "author": "Bernard D. Beitman",
                "date": "2016",
                "link": "https://a.co/d/baQLHSg",
                "img": "/images/booklist/connectingWithCoincidence.jpeg"
            },
            {
                "title": "Saved by the light",
                "author": "Dannion Brinkley",
                "date": "2008",
                "link": "https://amzn.to/3Lcz5gE",
                "img": "/images/booklist/savedByTheLight.jpeg"
            },
            {
                "title": "The Essential Vedanta",
                "author": "Eliot Deuttsch",
                "date": "2004",
                "link": "https://amzn.to/3QWlJXj",
                "img": "/images/booklist/theEssentialVedanta.jpg"
            },
            {
                "title": "One River, Many Wells",
                "author": "Mathew Fox",
                "date": "2004",
                "link": "https://amzn.to/3ePPQlJ",
                "img": "/images/booklist/oneRiverManyWells.jpg"
            },
            {
                "title": "Ordinary People as Monks and Mystics",
                "author": "Marsha Sinetar",
                "date": "2007",
                "notes_not_ready": "/documents/ordinaryPeopleAsMonksAndMysticsBooknotes.pdf",
                "link": "https://amzn.to/3QBcsna",
                "img": "/images/booklist/ordinaryPeopleAsMonksAndMystics.jpg"
            },
            {
                "title": "Broken Open",
                "author": "Elizabeth Lesser",
                "date": "2004",
                "notes_not_ready": "/documents/brokenOpenBooknotes.pdf",
                "link": "https://amzn.to/3dcGvnz",
                "img": "/images/booklist/brokenOpen.jpg"
            },
            {
                "title": "The Four-Fold Way: Walking the Paths of the Warrior, Teacher, Healer, and Visionary",
                "author": "Angeles Arrien",
                "date": "1993",
                "link": "https://amzn.to/3LaWlLS",
                "img": "/images/booklist/theFourFoldWay.jpg"
            }
        ]
    },
    {
        "section": "Philosophy",
        "books": [
            {
                "title": "Metamorphosis",
                "author": "Franz Kafka",
                "date": "1915",
                "link": "https://amzn.to/3BE8MwH",
                "img": "/images/booklist/metamorphosis.jpg"
            }, 
            {
                "title": "Fear and Trembling",
                "author": "Soren Kierkegaard",
                "date": "1843",
                "link": "https://amzn.to/3S3kAOi",
                "img": "/images/booklist/fearAndTrembling.jpg"
            },
            {
                "title": "Existentialism is a Humanism",
                "author": "Jean-Paul Sartre",
                "date": "2007",
                "link": "https://amzn.to/3QFK1og",
                "img": "/images/booklist/existentialismIsAHumanism.jpg"
            },
            {
                "title": "The Stranger",
                "author": "Albert Camus",
                "date": "1946",
                "link": "https://amzn.to/3Dpgb4i",
                "img": "/images/booklist/theStranger.jpg"
            },
            {
                "title": "The Castle",
                "author": "Franz Kafka",
                "date": "1926",
                "link": "https://amzn.to/3ddT0zk",
                "img": "/images/booklist/theCastle.jpg"
            },
            {
                "title": "The Trial",
                "author": "Franz Kafka",
                "date": "1959",
                "link": "https://a.co/d/4OYhpK9",
                "img": "/images/booklist/theTrial.jpg"
            },
            {
                "title": "Being and Nothingness",
                "author": "Jean Paul Sarte",
                "date": "1956",
                "link": "https://amzn.to/3qEHeB7",
                "img": "/images/booklist/beingAndNothingness.jpg"
            },
            {
                "title": "Simulacra & Simulation",
                "author": "Jean Baudrillard",
                "date": "1994",
                "notes_not_ready": "/documents/simulacraAndSimulationBooknotes.pdf",
                "link": "https://amzn.to/3RJx5if",
                "img": "/images/booklist/simulacraAndSimulation.jpg"
            },
        ]
    },
        {
        "section": "Dreams",
        "books": [
            {
                "title": "Lucid Dreaming - Gateway to the Inner Self",
                "author": "Robert Waggoner",
                "date": "2009",
                "link": "https://amzn.to/3BElcVn",
                "img": "/images/booklist/lucidDreaming.jpeg"
            },
            {
                "title": "Exploring the World of Lucid Dreaming",
                "author": "Stephen LaBerge",
                "date": "1991",
                "link": "https://amzn.to/3LeMOn7",
                "img": "/images/booklist/exploringTheWorldOfLucidDreaming.jpg"
            },
            {
                "title": "The Tibetan Yogas of Dream and Sleep",
                "author": "Tenzin Wangyal",
                "date": "1998",
                "link": "https://amzn.to/3Dkm7vq",
                "img": "/images/booklist/theTibetanYogasOfDreamAndSleep.jpg"
            },
            {
                "title": "The Wisdom of Your Dreams",
                "author": "Jeremy Taylor",
                "date": "2009",
                "link": "https://amzn.to/3xhKwOr",
                "img": "/images/booklist/theWisdomOfYourDreams.jpg"
            }
        ]
    },
    {
        "section": "Systems Theory",
        "books": [
            {
                "title": "Sociology: A Very Short Introduction",
                "author": "Steve Bruce",
                "date": "1999",
                "link": "https://amzn.to/3RSpU7g",
                "img": "/images/booklist/sociologyAVeryShortIntroduction.jpeg"
            },
            
            {
                "title": "Spiral Dynamics",
                "author": "Beck Cowan",
                "date": "1996",
                "link": "https://amzn.to/3BEi7ob",
                "img": "/images/booklist/spiralDynamics.jpg"
            },
            {
                "title": "The Systems View of Life",
                "author": "Fritjof Capra",
                "date": "2014",
                "notes_not_ready": "/documents/theSystemsViewOfLifeBooknotes.pdf",
                "link": "https://amzn.to/3RKDp9e",
                "img": "/images/booklist/theSystemsViewOfLife.jpg"
            },
            
        ]
    },
    {
        "section": "Meditation",
        "books": [
            {
                "title": "Mindfulness in Plain English",
                "author": "Bhante Henepola Gunaratana",
                "date": "2011",
                "link": "https://amzn.to/3eIzlb3",
                "img": "/images/booklist/mindfulnessInPlainEnglish.jpg"
            },
            {
                "title": "Mastering the Core Teachings of the Buddha: An Unusually Hardcore Dharma Book",
                "author": "Daniel Ingram",
                "date": "2018",
                "link": "https://amzn.to/3xlBIa2",
                "img": "/images/booklist/masteringTheCoreTeachingsOfTheBuddha.jpg"
            },
            {
                "title": "The Mind Illuminated",
                "author": "John Yates, Matthew Immergut, Jeremy Graves",
                "date": "2015",
                "link": "https://amzn.to/3QJp37I",
                "img": "/images/booklist/theMindIlluminated.jpg"
            },
            {
                "title": "Zen Mind. Beginner's Mind",
                "author": "Shunryū Suzuki",
                "date": "2020",
                "link": "https://amzn.to/3xlt3EG",
                "img": "/images/booklist/zenMindBeginnersMind.jpg"
            },
            {
                "title": "Focused and Fearless",
                "author": "Shaila Catherine",
                "date": "2008",
                "link": "https://amzn.to/3eCZGao",
                "img": "/images/booklist/focusedAndFearless.jpg"
            },
            {
                "title": "The Power Of Now",
                "author": "Eckhart Tolle",
                "date": "2004",
                "link": "https://amzn.to/3LrJl4Z",
                "img": "/images/booklist/thePowerOfNow.jpg"
            },
            {
                "title": "Beyond Mindfulness in Plain English",
                "author": "Bhante Henepola Gunaratana",
                "date": "2009",
                "link": "https://amzn.to/3eCZMig",
                "img": "/images/booklist/beyondMindfulnessInPlainEnglish.jpg"
            },
            {
                "title": "Introduction To Tantra",
                "author": "Thubten Yeshe",
                "date": "2014",
                "link": "https://amzn.to/3eNGk2D",
                "img": "/images/booklist/introductionToTantra.jpeg"
            },
            {
                "title": "Loving-Kindness in Plain English",
                "author": "Henepola Gunaratana",
                "date": "2017",
                "link": "https://amzn.to/3eIzISZ",
                "img": "/images/booklist/lovingKindnessInPlainEnglish.jpg"
            },
            {
                "title": "Neti-Neti Meditation",
                "author": "Andre Doshim Halaw",
                "date": "2013",
                "link": "https://amzn.to/3RGBZwt",
                "img": "/images/booklist/netiNetiMeditation.jpg"
            },
            {
                "title": "The Fire Kasina",
                "author": "Daniel Ingram",
                "date": "2015",
                "notes_not_ready": "/documents/theFireKasinaBooknotes.pdf",
                "link": "https://firekasina.org/fire-kasina-book/",
                "img": "/images/booklist/fireKasina.jpeg"
            }
        ]
    },
    {
        "section": "UFO's",
        "books": [
            {
                "title": "UFOs: Generals, Pilots, and Government Officials",
                "author": "Leslie Kean",
                "date": "2011",
                "link": "https://amzn.to/3S3pwCO",
                "img": "/images/booklist/Ufos.jpg"
            },
            {
                "title": "Making Contact: Preparing for the New Realities of Extraterrestrial Existence",
                "author": "Alan Steinfeld",
                "date": "2021",
                "link": "https://amzn.to/3ddmxZK",
                "img": "/images/booklist/makingContact.jpg"
            },
            {
                "title": "The Believer: Alien Encounters, Hard Science, and the Passion of John Mack",
                "author": "Ralph Blumenthal",
                "date": "2021",
                "link": "https://amzn.to/3eOCV3g",
                "img": "/images/booklist/theBeliever.jpg"
            },
            {
                "title": "Communion: A True Story",
                "author": "Whitley Strieber",
                "date": "1987",
                "link": "https://amzn.to/3eOCV3g",
                "img": "/images/booklist/communion.jpg"
            },
        ]
    },
    {
        "section": "Science",
        "books": [
            {
                "title": "Who Owns the Future?",
                "author": "Jaron Lanier",
                "date": "2013",
                "link": "https://amzn.to/3eNr9GG",
                "img": "/images/booklist/whoOwnsTheFuture.jpg"
            },
            {
                "title": "Pale Blue Dot",
                "author": "Carl Sagan",
                "date": "1994",
                "link": "https://amzn.to/3BeWfhZ",
                "img": "/images/booklist/paleBlueDot.jpg"
            },
            {
                "title": "The Cosmos",
                "author": "Carl Sagan",
                "date": "1983",
                "link": "https://amzn.to/3BNY8Up",
                "img": "/images/booklist/cosmos.jpg"
            },
            {
                "title": "The Universe in a Nutshell",
                "author": "Stephen Hawking",
            },
            {
                "title" : "The Singularity is Near",
                "author" : "Ray Kurzweil"
            },
            {
                "title": "Physics of The Impossible",
                "author": "Michio Kaku",
            },
        ]
    },
    {
        "section": "Breathing",
        "books": [
            {
                "title": "Breath",
                "author": "James Nestor",
            },
            {
                "title": "The Oxygen Advantage",
                "author": "Patrick Mckeown",
            },
            {
                "title": "Jaws - The Story of A Hidden Epidemic",
                "author": "Sandra Kahn",
            },
        ]
    },
    {
        "section": "Health and Nutrition",
        "books": [
            {
                "title": "Finding Ultra",
                "author": "Rich Roll",
            },
            {
                "title": "The UltraMind Solution",
                "author": "Mark Hyman",
            },
            {
                "title": "How Not To Die",
                "author": "Dr Gregor",
            }
        ]
    },
    {
        "section": "Self Help",
        "books": [
            {
                "title": "Daring Greatly",
                "author": "Brene Brown",
            },
            {
                "title": "Mastery",
                "author": "George Leonard",
            },
            {
                "title": "13 Things Mentally Strong People Don't Do",
                "author": "Amy Morin",
            },
            {
                "title": "The Four Agreements",
                "author": "Don Miguel Ruiz",
            },
            {
                "title": "The Happiness Trap",
                "author": "Russ Harris"
            },
            {
                "title": "The Big Leap",
                "author": "Gay Hendricks"
            },
            {
                "title": "The Gifts Of Imperfection",
                "author": "Brene Brown",
            },
            {
                "title": "Braving The Wilderness",
                "author": "Brene Brown",
            },
            {
                "title": "The Subtle Art of Not Giving a F*ck: A Counterintuitive Approach to Living a Good Life",
                "author": "Mark Manson",
            },
            {
                "title": "The 7 Habits Of Highly Effective People",
                "author": "Stephen R. Covey",
            },
            {
                "title": "Awaken The Giant Within",
                "author": "Anthony Robbins",
            },
            {
                "title": "How To Win Friends And Influence People",
                "author": "Dale Carnegie",
            }
        ]
    },
    {
        "section": "Law of Attraction",
        "books": [
            {
                "title": "Think and Grow Rich",
                "author": "Napoleon Hill",
            },
            {
                "title": "The Power of Your Subconscious Mind",
                "author": "Joseph Murphy",
            },
            {
                "title": "Psycho-Cybernetics",
                "author": "Maxwell Maltz",
            }
        ]
    },
    {
        "section": "Dating",
        "books": [
            {
                "title": "Models: Attract Women Through Honesty",
                "author": "Mark Manson",
            },
            {
                "title": "The Way of the Superior Man",
                "author": "David Deida",
            },
            {
                "title": "The Game",
                "author": "Neil Strauss",
            },
            {
                "title": "No More Mr. Nice Guy",
                "author": "Robert A. Glover",
            }
        ]
    },
    {
        "section": "Non Fiction",
        "books": [
            {
                "title": "If Truth Be Told: A Monk's Memoir",
                "author": "Om Swami",
            },
            {
                "title": "Memories, Dreams, Reflections",
                "author": "Carl Jung",
            },
            {
                "title": "Endurance: Shackleton's Incredible Voyage",
                "author": "Alfred Lansing",
            },
            {
                "title": "Walden",
                "author": "Henry David Thoreau",
            },
            {
                "title": "Man's Search for Meaning",
                "author": "Viktor E. Frankl, Harold S. Kushner, William J. Winslade",
            },
            {
                "title": "Into Thin Air: A Personal Account of the Mount Everest Disaster",
                "author": "Jon Krakauer",
            },
            {
                "title": "Left to Tell: Discovering God Amidst The Rwandan Holocaust",
                "author": "Immaculee Ilibagiza",
            },
            {
                "title": "Zen and the Art of Motorcycle Maintenance",
                "author": "Robert Pirsig",
            },
            {
                "title": "Total Recall: My Unbelievably True Life Story",
                "author": "Arnold Schwarzenegger",
            },
            {
                "title": "Brain on Fire",
                "author": "Susannah Cahalan",
            },
            {
                "title": "Pimp",
                "author": "Iceberg Slim",
            },
            
        ]
    },
    {
        "section": "Fiction",
        "books": [
            {
                "title": "Fight Club",
                "author": "Chuck Palahniuk",
            },
            {
                "title": "Jonathan Livingston Seagull",
                "author": "Richard Bach",
            },
            {
                "title": "The Alchemist",
                "author": "Paulo Coelho",
            },
            {
                "title": "The Little Prince",
                "author": "Antoine de Saint-Exupery",
            },
            {
                "title": "Solaris",
                "author": "Stanislaw Lem"
            }
        ]
    },
    {
        "section": "Classics",
        "books": [
            {
                "title": "Brave New World",
                "author": "Aldous Huxley",
            },
            {
                "title": "Wuthering Heights",
                "author": "Emily Bronte",
            },
            {
                "title": "The Awakening",
                "author": "Kate Chopin",
            },
            {
                "title": "Siddhartha",
                "author": "Hermen Hesse",
            },
            {
                "title": "Lolita",
                "author": "Vladimir Nabokov"
            },
            {
                "title": "Macbeth",
                "author": "William Shakespeare",
            },
            {
                "title": "Hamlet",
                "author": "William Shakespeare",
            },
            {
                "title": "Rosencrantz and Guildenstern are Dead",
                "author": "Tom Stoppard",
            },
            {
                "title": "Of Mice and Men",
                "author": "John Steinbeck",
            },
            {
                "title": "To Kill a Mockingbird",
                "author": "Harper Lee",
            },
            {
                "title": "Frankenstein",
                "author": "Mary Shelley",
            },
            {
                "title": "The Maltese Falcon",
                "author": "Dashiell Hammett",
            },
            {
                "title": "The Catcher in the Rye",
                "author": "J. D. Salinger",
            }
        ]
    },
    {
        "section": "Mathematics",
        "books": [
            {
                "title": "Calculus: One Variable",
                "author": "Salas L. Saturnino",
            },
            {
                "title": "Discrete Mathematics and its Applications",
                "author": "Kenneth Rosen",
            }
        ] 
    },
    {
        "section": "Finance",
        "books": [
            {
                "title": "The Intelligent Investor",
                "author": "Benjamin Grahm",
            }
        ]
    },
    {
        "section": "Politics",
        "books": [
            {
                "title": "Rage",
                "author": "Bob Woodward",
            }
        ]
    }

];

let countBooks = function (){
    let count = 0;
    for (let i = 0; i < books.length; i++){
        let section = books[i];
        for (let j = 0; j < section.books.length; j++){
            count++;
        }
    }
    return count;
}

export {countBooks};

export default books;