import React, { Component } from 'react';

class Background extends Component {
    render () {
        let file_path = "/images/";
        let profile_image = "IMG_4503.jpg";
        let profile_image_path = file_path + profile_image;
        return (
            <div className="text-section about-content-text-section">
                <h5 className="text-section-title">BACKGROUND</h5>
                <img src={profile_image_path} className="profile-image"></img>
                <p> I grew up in the suburbs of Atlanta, where, as an
                inquisitive, creative kid I was constantly learning about
                science, making art and building things. In college at Georgia
                State University, I applied these traits towards a bachelors in
                computer science, where I learned how to design systems working
                as a software engineer. After graduating in 2015, I worked over
                the years at several large companies in the Metro-Atlanta area,
                later moving to Los Angeles California, where I worked as a
                senior software engineer at NASAs Jet Propulsion lab supporting
                climate research.  </p> 
                <p> It was during my time at JPL, that
                my interests began to shift from software systems to social
                systems and individuals as I began to realize how, many of our
                scientific issues, such as climate change, rest upon human
                behavior and motivations. During the pandemic, I decided to go
                back to school to study psychology enrolling at Sofia
                University. I choose to go into the subfield of Transpersonal
                Psychology which seeks to study how we can derive a sense of
                meaning through our connection to something greater than
                ourselves, whether that be through a passion for the
                environment, belonging to a community, being part of a cause, or
                having a spiritual practice.  </p> 
                <p>Currently, I'm working as a paraprofessional counselor at a
                mental health center where I apply my holistic background to my
                work in helping those in need recover.</p>
            </div>
        );
    }
}

export default Background