import React, { Component } from 'react';
import Background from './Background.js';
import Education from './Education.js';
import Philosophy from './Philosophy.js';

class AboutContent extends Component {
    render () {
        return (
            <div className="about-content">
                <div className="description-text about-content-text">
                    <Background/>
                    <Philosophy/>
                    <Education/>
                </div>
            </div>
        );
    }
}

export default AboutContent