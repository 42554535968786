import React, { Component } from 'react';

class TheWorkshop extends Component {
    
    render () {
        let file_path = "/images/";
        let workshop_file_image = "IMG_5896.jpg";
        let workshop_download_image = file_path + workshop_file_image;
        return (
            <div className="text-section workshop-text-section">
                <h5 className="text-section-title">Workshops</h5>
                <img src={workshop_download_image} alt="" id="workshop_image" className="workshops-image" ></img>
                <p>
                    For my masters degree's professional practicum, I designed a
                    series of workshops exploring ones creative and intuitive
                    potential. The goal was to create an environment where
                    participants can get in touch with their own creative and
                    intuitive selves. The workshops involve a series of
                    questions and explorations, to invite the participants to
                    dive deeper into their inner selves. We work with artistic
                    collaging allowing the images we bring together to inform
                    our own personal stories, learning from what we find
                    ourselves making. I’ve displayed art in the gallery below
                    from participants of various sessions, as well as how the
                    experience went and lessons learned.
                </p>
                <p>
                    One friend commented how surprised she was at he meaning she
                    found in her own piece. She found the exercise tremendously
                    helpful in that it made the elusive concept of intuition
                    concrete, seeing how she had chosen images relating to herself
                    and how her art told a meaningful story about what she values in
                    herself. She also appreciated how, I emphasized the
                    non-judgemental nature of our process. There were no right or
                    wrong answers only things to be curious about and ponder.
                </p>
                <div style={{clear: "both"}}></div>
            </div>
        );
    }
}

export default TheWorkshop 