import { Component } from "react";
import Header from "./Header";

class More extends Component {
    render(){
        return (
        <div className="page long-page more-page">
            <Header/>
            {this.props.children}
        </div>);
    }
}

export default More;