import React, { Component } from 'react';

class Philosophy extends Component {
    render () {
        let file_path = "/images/";
        let holistic_image = "427202318_403796915388294_7563448951736894888_n.jpg";
        let holistic_image_path = file_path + holistic_image;
        return (
            <div className="text-section about-content-text-section">
                <h5 className="text-section-title">Philosophy</h5>
                <img src={holistic_image_path} className="holistic-image"></img>
                <p> I believe through an
                approach to being grounded in something larger we can approach
                life’s problems from a holistic perspective. Through this
                perspective we can create ethically sound systems, and transform
                people on an individual level. I believe having an appreciation
                for the mystery of life's beauty can give one a renewed sense of
                purpose and meaning. It leads to big picture thinking, as I've
                seen in a variety of contexts such in my work on climate
                research, personal experiences through gardening, art, 
                studies at Sofia University and work as a mental health counselor.
                </p>
            </div>
        );
    }
}

export default Philosophy