const videos = [
      { "title": "DonaldHoffman", "src": "https://www.youtube.com/embed/oYp5XuGYqqY" },
      { "title": "BreneBrown", "src": "https://www.youtube.com/embed/iCvmsMzlF7o" },
      { "title": "NimaArkaniHamed", "src": "https://www.youtube.com/embed/qTx98PUW6lE" },
      { "title": "SatishKumar", "src": "https://www.youtube.com/embed/uSLUd0veioU" },
      { "title": "DavidChalmers", "src": "https://www.youtube.com/embed/hTIk9MN3T6w" },
      { "title": "AliceRoberts", "src": "https://www.youtube.com/embed/x858bOny4Gw" },
      { "title": "BernardoKastrup", "src": "https://www.youtube.com/embed/lAB21FAXCDE" },
      { "title": "JillTartar", "src": "https://www.youtube.com/embed/8fLOxLXNsj4" },
      { "title": "RobertMoore", "src": "https://www.youtube.com/embed/61dPYU9DWIY" },
      { "title": "DeanRadin", "src": "https://www.youtube.com/embed/8-Tai-1Z29A" },
      { "title": "ByronKatie", "src": "https://www.youtube.com/embed/O60DfNd4k_Y" },
      { "title": "JefferyMartin", "src": "https://www.youtube.com/embed/8sHgb1OTHb4" },
      { "title": "JillBolteTaylor", "src": "https://www.youtube.com/embed/UyyjU8fzEYU" },
      { "title": "DavidRHawkins", "src": "https://www.youtube.com/embed/nbuL11RKNNY" },
      { "title": "KenWilber", "src": "https://www.youtube.com/embed/H4jcxxJ_0ok" },
      { "title": "Gangaji", "src": "https://www.youtube.com/embed/57uNTCb8sXM" },
      { "title": "ThomasCampbell", "src": "https://www.youtube.com/embed/Jzeb3INsKDQ" },
      { "title": "PeterRalston", "src": "https://www.youtube.com/embed/tFxl8zPLbeY" },
      { "title": "MarshaSinetar", "src": "https://www.youtube.com/embed/rlcbYYC6bT4" },
      { "title": "Overview Effect", "src": "https://www.youtube.com/embed/CHMIfOecrlo" },
      { "title": "DanielGoleman", "src": "https://www.youtube.com/embed/10J6crRacZg" },
      { "title": "JaronLanier", "src": "https://www.youtube.com/embed/qQ-PUXPVlos" },
      { "title": "DavidFravor", "src": "https://www.youtube.com/embed/aB8zcAttP1E" },
      { "title": "ElainePagels", "src": "https://www.youtube.com/embed/ZAQ8DsXDxQ0" },
      { "title": "OwenCook", "src": "https://www.youtube.com/embed/7dTD4wyGYuE" },
      { "title": "MonicaGagliano", "src": "https://www.youtube.com/embed/5z-kZZVyHdw" },
      { "title": "RogerPenrose", "src": "https://www.youtube.com/embed/hXgqik6HXc0" },
      { "title": "DavidLynch", "src": "https://www.youtube.com/embed/TqZpi8zAqe0" },
      { "title": "RickDoblin", "src": "https://www.youtube.com/embed/Q9XD8yRPxc8" },
      { "title": "JaneGoodall", "src": "https://www.youtube.com/embed/K9TiRytw-OU" },
      { "title": "ShinzenYoung", "src": "https://www.youtube.com/embed/kfI6q38nLPc" },
      { "title": "GodelsIncompletenessTheorem", "src": "https://www.youtube.com/embed/O4ndIDcDSGc" },
      { "title": "RamDass", "src": "https://www.youtube.com/embed/LCiB9oMnIbI" },
      { "title": "MarionWoodman", "src": "https://www.youtube.com/embed/PTYYJMvUodM" },
      { "title": "JosephCampbell", "src": "https://www.youtube.com/embed/aGx4IlppSgU" },
      { "title": "FritjofCapra", "src": "https://www.youtube.com/embed/bJAtSB51o9s" },
      { "title": "ShunyamurtiSatsang", "src": "https://www.youtube.com/embed/F6oChQvUsa4" },
      { "title": "Leanne Whitney", "src": "https://www.youtube.com/embed/dV0BfN3Xijo" },
      { "title": "ErvinLaszlo", "src": "https://www.youtube.com/embed/TMWxjk-yWiI" },
      { "title": "StanGrof", "src": "https://www.youtube.com/embed/La9qnPwx3Nc" },
      { "title": "JuliaButterflyHill", "src": "https://www.youtube.com/embed/T4dfkdMD1eo" },
      { "title": "ThichNhatHanh", "src": "https://www.youtube.com/embed/NJ9UtuWfs3U" },
      { "title": "RobertWaggoner", "src": "https://www.youtube.com/embed/Md7c7b0t7WY" },
      { "title": "MarianneWilliamson", "src": "https://www.youtube.com/embed/IiEmfXECD9k" },
      { "title": "DickProenneke", "src": "https://www.youtube.com/embed/hy-4NxJRxNQ" },
      { "title": "LamaTsultrimAllione", "src": "https://www.youtube.com/embed/CbaK72Kcj_M" },
      { "title": "SongOfNewEarth", "src": "https://www.youtube.com/embed/Auul3_5RCco" },
      { "title": "DavidDeida", "src": "https://www.youtube.com/embed/0-4IHCPCiJ0" },
      { "title": "GenevieveVonPetzinger", "src": "https://www.youtube.com/embed/AC-x8ZQyKJ4" },
      { "title": "AlexHubberman", "src": "https://www.youtube.com/embed/2ekdc6jCu2E" },
      { "title": "YongeyMingyurRinpoche", "src": "https://www.youtube.com/embed/ukTaodQfYRQ" },
      { "title": "Veritasium", "src": "https://www.youtube.com/embed/t-_VPRCtiUg" },
      { "title": "NormandiEllis", "src": "https://www.youtube.com/embed/uQfnnVGCKLs" },
      { "title": "CarlSagan", "src": "https://www.youtube.com/embed/UnURElCzGc0" },
      { "title": "SamHarris", "src": "https://www.youtube.com/embed/ITTxTCz4Ums" },
      { "title": "PatrickMckeown", "src": "https://www.youtube.com/embed/mBqGS-vEIs0" }
    ];

export default videos; 