import React, { useState, useEffect, useRef } from 'react';
import Home from './Home/Home.js';
import Workshops from './Workshops/Workshops.js';
import Gallery from './ArtGallery/Gallery.js';
import Essays from './Essays/Essays.js';
import About from './About/About.js';
import Footer from './Footer/Footer.js';

const PortfolioPages = () => {
 

  return (
    <div className="portfolio-pages">
      <Home />
      <Workshops id="workshops" className="portfolio-page long-page long-workshops default-font-size" />
      <Gallery id="gallery" className="portfolio-page long-page long-gallery default-font-size" />
      <Essays id="essays" className="portfolio-page default-font-size" />
      <About id="about" className="portfolio-page long-page long-about default-font-size" />
      <Footer id="more" className="portfolio-page default-font-size" />
    </div>
  );
}

export default PortfolioPages;