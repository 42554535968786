import React, { useState, useEffect } from 'react';
import MasonryGrid from './MasonryGrid';
import useWindowWidth from '../../Utils/useWindowWidth';

// ================================================================================= 
// Component: ResizeableMasonryGrid
// Description: Creates a ResizeableMasonryGrid of images
// ================================================================================= 

const ResizeableMasonryGrid = (props) => {
    const { images, responsiveColConfig, responsiveGridWidth } = props;
    const windowWidth = useWindowWidth();
    const gridWidth = windowWidth * responsiveGridWidth.find(({threshold}) => windowWidth < threshold).percentage; 
    const numberOfColumns = responsiveColConfig.find(({ threshold }) => windowWidth < threshold).numberOfColumns;
    return <MasonryGrid images={images} numberOfColumns={numberOfColumns} gridWidth={gridWidth}/>
};

export default ResizeableMasonryGrid;