import React from 'react';
import ReactDOM from 'react-dom';

//style dependencies
import './css/index.css';

//css for apps required by the components
import './css/apps/masonry_grid.css'
import './css/apps/modal.css'
import './css/apps/lazyIframe.css'

//css for components
import './css/components/home.css';
import './css/components/workshops.css';
import './css/components/gallery.css';
import './css/components/essays.css';
import './css/components/about.css';
import './css/components/footer.css';

//css for more
import './css/more/more.css'
import './css/more/booklist.css'
import './css/more/personal_gallery.css'
import './css/more/videos.css'

//responsiveness for different view sizes
import './css/responsive/mobile.css';
import './css/responsive/tablets.css';
import './css/responsive/desktop.css';

//bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";
//bootstrap bundle js 
import "bootstrap/dist/js/bootstrap.bundle.min";

// Components
import App from './components/App';

// Utilities
import registerServiceWorker from './util/registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();