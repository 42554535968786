import React, { Component } from 'react';

class GalleryHeader extends Component {
  render() {
    return (
        <div className="page-header gallery-header">
          <h3 className="gallery-header-h3">Art Gallery</h3>
        </div>
    );
  }
}

export default GalleryHeader;