import React, { Component } from 'react';
import WorkshopsTagline from './WorkshopsTagline.js'

class WorkshopsHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="page-header workshops-header">
          <h3>Introduction</h3>
          <WorkshopsTagline></WorkshopsTagline>
        </div>
    );
  }
}

export default WorkshopsHeader;