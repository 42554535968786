import React, { Component } from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import {ScrollToHashElement} from './Apps/ScrollToHashElement/ScrollToHashElement.js';

import PortfolioPages from './Portfolio/PortfolioPages.js';
import Booklist from './More/Booklist/Booklist.js';
import PersonalGallery from './More/Gallery/PersonalGallery.js';
import Videos from './More/Videos/Videos.js';
import More from './More/More.js';
import Contact from './More/Contact/Contact.js';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
          <ScrollToHashElement />
          <Routes>
            <Route exact path="/" element={<PortfolioPages />}/>
            <Route exact path="/portfolio" element={<PortfolioPages />}/>
            <Route path="/booklist" element={<More><Booklist/></More>} /> 
            <Route exact path="/gallery" element={<More><PersonalGallery/></More>}/>
            <Route path="/videos" element={<More><Videos/></More>} />
            <Route path="/contact" element={<More><Contact/></More>} />
          </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
