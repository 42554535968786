import React, { Component } from 'react';

import LazyLoadComponent from '../../Apps/LazyComponent/LazyLoadComponent.js';

import EssaysHeader from './EssaysHeader.js'
import EssaysList from './EssaysList.js'
import EssayTagline from './EssayTagline.js';


class Essays extends Component {
  render() {
    return (
      <div id="essays" className="page essays-page">
        <EssaysHeader></EssaysHeader>
        {/*<EssayTagline></EssayTagline>*/}
        <EssaysList></EssaysList>
      </div>
    );
  }
}

export default Essays;