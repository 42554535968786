import React, { Component } from 'react';
import Header from './Header.js';
import SiteTitleSection from './SiteTitleSection.js';

class Home extends Component {
  render() {
    return (
      <div id="home" className="portfolio-page default-font-size">
        <div className="page home-page">
          <Header/>
          <SiteTitleSection/> 
        </div>
      </div>
    );
  }
}

export default Home;
