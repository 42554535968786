import React, { Component } from 'react';

class Education extends Component {
    render () {
        let file_path = "/images/";
        let education_image = "IMG_5471.jpg";
        let education_image_path = file_path + education_image;
        return (
            <div className="text-section about-content-text-section">
                <h5 className="text-section-title">Education</h5>
                <img src={education_image_path} className="education-image"></img>
                <p>
                    My concentration was on creative expression and
                    transformation. I’m learning how we can approach the areas
                    of creativity, intuition, and spiritual transformation in a
                    rigorous manner, giving structure to concepts that can often
                    be elusive and difficult to understand. I'm especially
                    interested in observing how the unconscious operates and how
                    intuition naturally guides us towards wholeness. Moral and
                    ethical frameworks derived by these principles of wholeness
                    can be used to design systems for change. I believe when an
                    organization is designed with intentionality, respect, and
                    reverence towards the creative process, it leads to a
                    harmonious environment where people are free to
                    authentically grow.
                </p>
            </div>
        );
    }
}

export default Education