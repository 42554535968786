import React, { useRef } from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';

const LazyIframe = ({ url, title }) => {
  const containerRef = useRef();
  const lockRef = useRef(false);
  const { isIntersecting } = useIntersectionObserver(containerRef);

  if (isIntersecting) lockRef.current = true;

  const hiddenDivStyle = { 
    overflow: 'hidden', 
    paddingTop: '56.25%', 
    marginBottom: '65px', 
    position: 'relative', 
    width: '100%'
  };

  const iFrameStyle = {
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
  };

  const iFrameAllowValues = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";

  return (
    <div style={hiddenDivStyle} ref={containerRef} className="lazyIframeWrapper">
      {lockRef.current && (
        <iframe title={title} style={iFrameStyle} className="video" src={url} allow={iFrameAllowValues} allowFullScreen></iframe>
      )}
    </div>
  );
};

export default LazyIframe;