import React from 'react';
import essayInfo from '../../../data/essays.js';
import { useNavigate } from 'react-router-dom';

const Essays = () => {

  const navigate = useNavigate();

  const updateNavigation = () => {
    navigate("/portfolio#essays", {replace: true})
  };

  return (
    <div className="essay-links">
      {essayInfo.map((essay, index) => (
        <div className="link-size default-link essay-link" key={index}>
          <a href={essay.url} onClick={updateNavigation}>{essay.title}</a>
        </div>
      ))}
    </div>
  );
};

export default Essays;