const randomSortOrder = () => Math.random() - 0.5;

export const image_path = "/images/art/";

export const images = [
    { "image": "implicate_order.jpg", "dataset": {"description": ""}, "alt": "", "naturalWidth": 605, "naturalHeight": 806},
    { "image": "fractals.jpg",  "dataset": {"description": ""}, "alt": "", "naturalWidth": 543, "naturalHeight": 731},
    { "image": "figure3.jpg",   "dataset": {"description": ""}, "alt": "", "naturalWidth": 576, "naturalHeight": 720},
    { "image": "figure11.jpg",  "dataset": {"description": ""}, "alt": "", "naturalWidth": 605, "naturalHeight": 806},
    { "image": "figure4.jpg",   "dataset": {"description": ""}, "alt": "", "naturalWidth": 576, "naturalHeight": 720},
    { "image": "butterfly.jpg", "dataset": {"description": ""}, "alt": "", "naturalWidth": 512, "naturalHeight": 512},
    { "image": "figure2.jpg",   "dataset": {"description": ""}, "alt": "", "naturalWidth": 504, "naturalHeight": 610},
    { "image": "figure8.jpg",   "dataset": {"description": ""}, "alt": "", "naturalWidth": 454, "naturalHeight": 605},
    { "image": "figure7.jpg",   "dataset": {"description": ""}, "alt": "", "naturalWidth": 454, "naturalHeight": 605},
    { "image": "figure9.jpg",   "dataset": {"description": ""}, "alt": "", "naturalWidth": 454, "naturalHeight": 605},
    { "image": "figure10.jpg",  "dataset": {"description": ""}, "alt": "", "naturalWidth": 454, "naturalHeight": 605},
    { "image": "figure1.jpg",   "dataset": {"description": ""}, "alt": "", "naturalWidth": 498, "naturalHeight": 787},
    { "image": "figure6.jpg",   "dataset": {"description": ""}, "alt": "", "naturalWidth": 605, "naturalHeight": 806},
    { "image": "figure5.jpg",   "dataset": {"description": ""}, "alt": "", "naturalWidth": 605, "naturalHeight": 806},
    { "image": "paper_figure.jpg", "dataset": {"description": ""}, "alt": "", "naturalWidth": 521, "naturalHeight": 609},
    { "image": "astronaut_side.jpg", "dataset": {"description": ""}, "alt": "", "naturalWidth": 576, "naturalHeight": 576},
    { "image": "astronaut_front.jpg", "dataset": {"description": ""}, "alt": "", "naturalWidth": 720, "naturalHeight": 720}
];

// Function to add 'src' property to each image object
export const imagesWithSrc = (() => {
    return images.map(image => ({
        ...image,
        src: `${image_path}${image.image}` // /images/art/fractals.jpg
    }));
})(images);

export const imagesRandomized = [...imagesWithSrc].sort(randomSortOrder);

export default images;