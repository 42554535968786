import React, { Component, useRef } from 'react';

class VideoHeader extends Component {

    render () {
        return (
            <div className="more-page-header video-header">
                <h6>Wisdom, ideas, perspectives, stories, experiences and knowledge from extraordinary individuals.</h6>
            </div>
        );
    }
}

export default VideoHeader