import React, { Component } from 'react';
import Introduction from './Introduction';
import TheWorkshop from './TheWorkshop';
import AwakeningIntuion from './AwakeningIntuition';
import Groups from './Groups';
import GardenGroups from './ArtGroups';

class WorkshopsDescription extends Component {
    render () {
        return (
            <div className="workshops-description">
                <div className="description-text workshops-text">
                    <Introduction/>
                    <Groups/>
                    <GardenGroups />
                    <TheWorkshop/>
                    <AwakeningIntuion/>
                </div>
            </div>
        );
    }
}

export default WorkshopsDescription 