import React, { useState } from 'react';
import _ from 'lodash';
import utils from '../../Utils/utils.js';

// ================================================================================= 
// Module: GridBuilder
// Description: Create and configure MasonryGrid representations
//   - Usable by other layout components
//   - Maintains grid state as 2d arary of MasonryImages 
//   - Provides useful grid functions
// ================================================================================= 

// GridBuilder :: () -> GridBuilder
// Description: Constructor for the GridBuilder component
// Returns: GridBuilder
// {
//   grid: [[MasonryImage]],
//   build: Function,
//   gridHeight: Function,
//   flatGrid: Function
// }
const GridBuilder = (numberOfColumns, gridWidth, gutter = 10) => {
  
  // build :: ([HTMLImageElement], Number, Number) -> Void
  const build = (images) => 
    _.flow(
      createMasonryImages, 
      processMasonryImages(scaleMasonryImage(gridWidth, numberOfColumns, gutter)),
      createMasonryGrid(numberOfColumns), 
      tileMasonryGrid(tileGridByRows(tileRowUsing(shortestColumnFirst))), 
    )(images);

  // createMasonryImages :: ([HTMLImageElement]) -> [MasonryImage]
  const createMasonryImages = (images) => images?.map(imageToMasonryImage);

  // imageToMasonryImage :: (HTMLImageElement) -> MasonryImage
  const imageToMasonryImage = ({src, alt, dataset, naturalWidth, naturalHeight}) => ({src, alt, dataset, naturalWidth, naturalHeight, top: 0, left: 0});

  // processMasonryImages :: (f) -> ([MasonryImage]) -> [MasonryImage]
  const processMasonryImages = (imageProcessor) => (masonryImages) => masonryImages?.map(imageProcessor);

  // scaleMasonryImage :: (Num, Num) -> (MasonryImage, i) -> MasonryImage
  const scaleMasonryImage = (gridWidth, numberOfColumns, gutter) => (masonryImage, index = 0) => {
    const colWidth = calculateMasonryImageProperty.colWidth(gridWidth, numberOfColumns, gutter);
    const aspectRatio = calculateMasonryImageProperty.aspectRatio(masonryImage);
    const height = calculateMasonryImageProperty.heightScaledToWidth(colWidth, aspectRatio);
    return {...masonryImage, index, aspectRatio, gutter, colWidth, colHeight: gutter, width: colWidth, height};
  };
  
  // createMasonryGrid :: (Num, Num) -> ([MasonryImage]) -> [[MasonryImage]]
  const createMasonryGrid = (numberOfColumns) => (masonryImages) => _.chunk(masonryImages, numberOfColumns);

  // tileMasonryGrid :: (f) -> ([[MasonryImage]]) -> [[MasonryImage]]
  const tileMasonryGrid = (gridTilingMethod) => (masonryGrid) => gridTilingMethod(masonryGrid);

  // tileGridByRows :: (f) -> ([[MasonryImage]]) -> [[MasonryImage]]
  const tileGridByRows = (rowTilingMethod) => (masonryGrid) => (
    masonryGrid.reduce((tiledGrid, currentRow) => (
      _.concat(tiledGrid, [rowTilingMethod(_.last(tiledGrid), currentRow)])
    ),[]) // begin with an empty grid []
  );
  
  // tileRowUsing :: (f) -> ([MasonryImage], [MasonryImage]) -> [MasonryImage]
  const tileRowUsing = (tilingAlgorithm) => (previousRow, currentRow) => {
      const colHeightsOfPreviousRow = gridFunctions.colHeightsOfRow(previousRow || currentRow); // If no previous row use current row
      const currentRowWithPositions = addPositionsToRow(tilingAlgorithm, currentRow, colHeightsOfPreviousRow);
      return _.sortBy(currentRowWithPositions, 'left');
  };

  // addPositionsToRow :: (f, [MasonryImage], Num) -> [MasonryImage]
  const addPositionsToRow = (tilingAlgorithm, row, columnHeights) => row.map(tilingAlgorithm(columnHeights));

  // shortestColumnFirst :: ([Num]) -> (MasonryImage, Num) -> MasonryImage
  const shortestColumnFirst = (columnHeights) => (masonryImage, n) => {
    const nextShortestColumn = columnHeights.nthShortestColumn(n);
    const nextShortestColumnPosition = columnHeights.nthShortestColumnPosition(n);
    return {
      ...masonryImage, 
      left: calculateMasonryImageProperty.left(masonryImage, nextShortestColumnPosition),
      top: calculateMasonryImageProperty.top(nextShortestColumn),
      colHeight: calculateMasonryImageProperty.colHeight(masonryImage, nextShortestColumn)
    };
  };

  // Additional Funcions ----------------------------------------------------------------------- 
  const calculateMasonryImageProperty = {
    left: (masonryImage, tilePosition) => (((masonryImage?.gutter + (masonryImage?.gutter * tilePosition))+ (tilePosition * masonryImage?.colWidth)) || 0), // left :: (MasonryImage, Number) -> Number
    top: (colHeight) => colHeight, // top :: (Number) -> Number
    colHeight: (masonryImage, colHeight) => colHeight + masonryImage?.height + masonryImage?.gutter || 0, // colHeight :: (MasonryImage, Number) -> Float 
    colWidth: (gridWidth, numberOfColumns, gutter) => utils.roundedFloat(utils.safeDivide((gridWidth - (gutter + (numberOfColumns*gutter))), numberOfColumns)), // colWidth :: (Number, Number) -> Float
    aspectRatio: (image) => utils.safeDivide(image?.naturalWidth, image?.naturalHeight), // aspectRatio :: (HTMLImageElement) -> Number
    heightScaledToWidth: (width, aspectRatio) => utils.roundedFloat(utils.safeDivide(width, aspectRatio)) // heightScaledToWidth :: (Number, Number) -> Float
  };

  const gridFunctions = {
    colHeightsOfRow: (masonryRow) => {
      const columnHeights = masonryRow.map((masonryImage) => masonryImage?.colHeight || 0); // All colHeights in row 
      const sortedByShortest = _.sortBy(columnHeights);
      const indicesOfSortedByShortest = _.sortBy(_.range(masonryRow?.length), (n) => columnHeights[n]);
      const sortedByTallest = sortedByShortest?.reverse();
  
      return {
        columnHeights, sortedByTallest,
        nthShortestColumnPosition: (n) => indicesOfSortedByShortest[n], // nthShortestColumnPosition :: (Number) -> Number
        nthShortestColumn: (n) => columnHeights[indicesOfSortedByShortest[n]] // nthShortestColumn :: (Number) -> Number
      };
    },
    calculateGridHeight: (grid) => _.head(gridFunctions.colHeightsOfRow(_.last(grid)).sortedByTallest), // calculateGridHeight :: ([[MasonryImage]]) -> Number
    gridHeight: (grid) => grid ? gridFunctions.calculateGridHeight(grid) : 0, // gridHeight :: () -> Number
    flatGrid: (grid) => grid ? utils.flattenedCleaned(grid) : [], // flatGrid :: () -> Array
    rowNotOutOfBounds: (rowIndex, grid) => grid ? rowIndex < grid?.length : false,
    numOfGridRows: (grid) => grid?.length || 0,
    numOfGridCols: (grid) => grid ?  (grid[0]?.length || 0) : 0,
    numOfColsInRow: (row) => row?.length,
    isLastRow: (rowIndex) => rowIndex === gridFunctions.numOfGridRows(grid) - 1,
    isTopRowImage: (image) => image?.top === 0 || false,
    isRightMostImage: (image, row) => image?.left === _.maxBy(row, 'left')?.left,
    isLeftMostImage: (image, row) => image?.left === _.minBy(row, 'left')?.left
  };

  // used to calculate if an image touches an edge of the grid (top, right, bottom or left)
  const edgeCalculations = (grid) => {
    const noBottomNeighbor = (image, rowIndex) => gridFunctions.rowNotOutOfBounds(rowIndex+1, grid) && utils.notAny(grid[rowIndex+1], 'left', image?.left);
    const noLeftNeighbor = (image, row) => !utils.any(row, 'left', utils.roundedFloat(image?.left - image?.colWidth));
    const noRightNeighbor = (image, row) => !utils.any(row, 'left', utils.roundedFloat(image?.left + image?.colWidth));
    
    const isTopEdge = (image, rowIndex) => gridFunctions.isTopRowImage(image); // isTopEdge :: (MasonryImage, Number) -> Boolean
    const isRightEdge = (image, rowIndex) => gridFunctions.isRightMostImage(image, grid[rowIndex]) || noRightNeighbor(image, grid[rowIndex]); // isRightEdge :: (MasonryImage, Number) -> Boolean
    const isBottomEdge = (image, rowIndex) => gridFunctions.isLastRow(rowIndex) || noBottomNeighbor(image, rowIndex); // isBottomEdge :: (MasonryImage, Number) -> Boolean
    const isLeftEdge = (image, rowIndex) => gridFunctions.isLeftMostImage(image, grid[rowIndex]) || noLeftNeighbor(image, grid[rowIndex]); // isLeftEdge :: (MasonryImage, Number) -> Boolean

    return {isTopEdge, isRightEdge, isBottomEdge, isLeftEdge};
  };

  return {
    build,
    gridHeight: (grid) => gridFunctions.gridHeight(grid),
    flatGrid: (grid) => gridFunctions.flatGrid(grid),
    isTopEdge: (grid) => edgeCalculations(grid).isTopEdge, isRightEdge: (grid) => edgeCalculations(grid).isRightEdge,
    isBottomEdge: (grid) => edgeCalculations(grid).isBottomEdge, isLeftEdge: (grid) => edgeCalculations(grid).isLeftEdge,
    exportedForTesting: {
      createMasonryImages,
      processMasonryImages,
      scaleMasonryImage,
      createMasonryGrid,
      tileMasonryGrid,
      tileGridByRows,
      tileRowUsing,
      shortestColumnFirst,
      calculateMasonryImageProperty,
      gridFunctions,
      edgeCalculations
    }
  };
};

export default GridBuilder;