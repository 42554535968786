import { useState, useEffect, useRef } from 'react';

const createTransparentImage = (width, height, opacity=0.2) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    //ctx.clearRect(0, 0, width, height);
    // Set a semi-transparent fill color
    ctx.fillStyle = `rgba(0, 0, 0, ${opacity})`;
    ctx.fillRect(0, 0, width, height);
    return canvas.toDataURL();
};

const useLazyLoadPlaceholderImage = (src, width, height) => {
    const [imgSrc, setImgSrc] = useState(createTransparentImage(width, height));
    const imgRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const loadImage = () => {
                        if (window[src]) {
                            setImgSrc(src);
                        } else {
                            const img = new Image();
                            img.src = src;
                            img.onload = () => {
                                window[src] = img;
                                setImgSrc(src);
                            };
                        }
                    };
                    loadImage();
                    observer.disconnect();
                }
            });
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, [src]);

    return [imgSrc, imgRef];
};

export default useLazyLoadPlaceholderImage;