import React, { useState, useEffect, useRef } from 'react';
import LazyLoadComponent from '../../Apps/LazyComponent/LazyLoadComponent.js';
import GalleryGrid from './GalleryGrid.js';
import GalleryHeader from './GalleryHeader.js';

const Gallery = (props) => {
    return (
        <div id={props.id} className={props.className}>
            <div className="workshops-gallery">
                <GalleryHeader/>
                <GalleryGrid/>
            </div>
        </div>
    );
};

export default Gallery;