import React, { Component } from 'react';
import PersonalMasonryGallery from './PersonalMasonryGallery.js';
import PersonalGalleryHeader from './PersonalGalleryHeader.js';

class PersonalGallery extends Component {
    
    render() {
        return (
            <div className="personal-gallery-page">
                <PersonalGalleryHeader/>
                <PersonalMasonryGallery />
            </div>
        );
    };
};

export default PersonalGallery;