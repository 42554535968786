import { useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

const removeHashCharacter = (str) => { return str.slice(1); };

//SITE WIDE, ON REFRESH
const ScrollToHashElement = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const hashElement = document.getElementById(removeHashCharacter(hash));
      if (hashElement) {
        scrollElementIntoView(hashElement);
      }
    }
  }, [location]);

  const scrollElementIntoView = (element) => {
    element.scrollIntoView({
      behavior: "auto",
      // block: "end",
      inline: "nearest",
    });
  };
  return null;
};

//INDIVIDUAL LINKS
const ScrollToHashElementLink = ({ href, children, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const hashElement = document.getElementById(removeHashCharacter(href));
    
    const scrollElementIntoView = (element) => {
      element.scrollIntoView({
        behavior: "smooth",
        // block: "end",
        inline: "nearest",
      });
    };

     // Add the hash to the URL without triggering another scroll
     const newURL = `${location.pathname}${href}`;
     navigate(newURL);

    if (hashElement) {
      scrollElementIntoView(hashElement);
    }
  };
  return <a href={href} onClick={handleLinkClick} {...rest}>{children}</a>;
};

export { ScrollToHashElement, ScrollToHashElementLink };