import React, { Component } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const header = () =>  {
  let { pathname } = useLocation();
  return (
      <div className="more-header">
        <h1 id="more-site">ryanbHenao.com</h1>
          <div className="more-nav-items">
            <NavLink className="more-nav-link" to="/portfolio">Portfolio</NavLink>
            <NavLink className="more-nav-link" to="/booklist">Booklist</NavLink>
            <NavLink className="more-nav-link" to="/gallery">Gallery</NavLink>
            <NavLink className="more-nav-link" to="/videos">Videos</NavLink>
            <NavLink className="more-nav-link" to="/contact">Contact</NavLink>
          </div>
      </div>
  );
}

export default header;