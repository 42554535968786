import React, { useState, forwardRef } from 'react';

const ModalImage = forwardRef(({ src, alt, children, ...props }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) setIsModalOpen(false);
  };

  return (
    <>
      <img className="modal-image default-link" onClick={openModal} src={src} alt={alt} ref={ref} {...props} />
      {isModalOpen &&
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-content">
            <div className="modal-image-header">
              <div className="modal-alt">{alt}</div>
              <button className="modal-close-button" onClick={closeModal}>Close</button>
            </div>
            <img src={src} className="modal-overlay-image" alt={alt} />
            {children}
          </div>
        </div>
      }
    </>
  );
});

export default ModalImage;