import React, { Component } from 'react';

import { countBooks } from '../../../data/books.js'

class BooklistHeader extends Component {
    render() {
        return (
            <div className="more-page-header booklist-header">
                <h6>My top books read on Transpersonal Psychology.</h6>
            {/*<div className="booklist-description">
                <p>The majority of my readings are on transpersonal psychology,
                consciousness, spirituality, and metaphysics, as those areas
                fascinate me most. The books are in no particular order, but I
                have grouped them into different categories to make it easier to
                find something interesting. Books with a <b><i>notes </i></b>
                link are one's which I have added my own personal detailed
                booknotes.</p> <i>There are
                currently&nbsp;<mark>{countBooks()}</mark>&nbsp;books in this
                list.</i>
            </div>*/}
            </div>
        );
    }
}

export default BooklistHeader;