import React, { Component } from 'react';
import ResizeableMasonryGridMasonryGrid from '../../Apps/MasonryGrid/ResizeableMasonryGrid.js';
import { imagesRandomized as images } from '../../../data/images.js';

class PersonalMasonryGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfColumns: 4,
      gridWidth: 1800,
      images: images,
      responsiveColConfig: [
        { label: 'small', threshold: 800, numberOfColumns: 2},
        { label: 'medium', threshold: 1024, numberOfColumns: 3},
        { label: 'large', threshold: Number.MAX_SAFE_INTEGER, numberOfColumns: 4}
      ],
      responsiveGridWidth: [
        { label: 'small', threshold: 800, percentage: 0.96},
        { label: 'medium', threshold: 1024, percentage: 0.90},
        { label: 'large', threshold: Number.MAX_SAFE_INTEGER, percentage: 0.80}
      ],
    };
  }

  render() {
    return (
        <div className="more-page-body personal-masonry-gallery-container"> 
            <ResizeableMasonryGridMasonryGrid images={this.state.images} responsiveColConfig={this.state.responsiveColConfig} responsiveGridWidth={this.state.responsiveGridWidth}/> 
        </div>
    );
  }
}

export default PersonalMasonryGallery;