import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const More = () => {

  const navigate = useNavigate();

  const updateNavigation = () => {
    navigate("/portfolio#more", {replace: true})
  };

  return (
    <div className="footer-more">
      <div className="page-header"><h3>More</h3></div>
      <NavLink className="link-size default-link" to="/booklist" onClick={updateNavigation}>Booklist</NavLink>
      <NavLink className="link-size default-link" to="/gallery" onClick={updateNavigation}>Personal Art Gallery</NavLink>
      <NavLink className="link-size default-link" to="/videos" onClick={updateNavigation}>Videos</NavLink>
      <NavLink className="link-size default-link" to="/contact" onClick={updateNavigation}>Contact Info</NavLink>
    </div>
  );
};

export default More;