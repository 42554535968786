import React, { Component, useEffect, useState } from 'react';
import LazyLoadComponent from '../../Apps/LazyComponent/LazyLoadComponent.js';
import WorkshopsHeader from './WorkshopsHeader.js';
import WorkshopsDescription from './WorkshopsDescription.js';
import WorkshopsTagline from './WorkshopsTagline.js'

class Workshops extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id={this.props.id} className={this.props.className}>
        <div className="workshop-layout">
          <WorkshopsHeader></WorkshopsHeader>
          <WorkshopsDescription></WorkshopsDescription>
        </div>
      </div>
    );
  }
}

export default Workshops;