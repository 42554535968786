const randomSortOrder = () => Math.random() - 0.5;

export const image_path = "/images/art/groups/";

export const images = [
    { 'image': 'IMG_4594.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 3024, naturalHeight: 4032},
    { 'image': 'IMG_4597.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 3007, naturalHeight: 2920},
    { 'image': 'IMG_4599.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 2277, naturalHeight: 2409},
    { 'image': 'IMG_4600.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 3024, naturalHeight: 3338},
    { 'image': 'IMG_4627.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 3024, naturalHeight: 4032},
    { 'image': 'IMG_4629.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 2711, naturalHeight: 3559},
    { 'image': 'IMG_4593.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 3024, naturalHeight: 4032},
    { 'image': 'IMG_4829.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 2300, naturalHeight: 3542},
    { 'image': 'IMG_4927.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 3024, naturalHeight: 3024},
    { 'image': 'IMG_4934.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 2701, naturalHeight: 2888},
    { 'image': 'IMG_4930.jpg', 'dataset': {'description': ''}, 'alt': '', naturalWidth: 2571, naturalHeight: 2057},
    //{ 'image': 'IMG_4626.jpg', 'description': '', 'alt': ''},
    //{ 'image': 'IMG_4598.jpg', 'description': '', 'alt': ''},
    //{ 'image': 'IMG_4633.jpg', 'description': '', 'alt': ''},
    //{ 'image': 'IMG_4636.jpg', 'description': '', 'alt': ''},
];

// Function to add 'src' property to each image object
export const imagesWithSrc = (() => {
    return images.map(image => ({
        ...image,
        src: `${image_path}${image.image}`
    }));
})(images);

export const imagesRandomized = [...imagesWithSrc].sort(randomSortOrder);

export default images;