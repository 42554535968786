const randomSortOrder = () => Math.random() - 0.5;

export const image_path = "/images/art/workshops/";

export const images = [
    { 'image': 'Kate_collage.jpg', 'dataset': {'description': 'My friend who made this felt a strong resonance with the themes of the calmness, presence, and clarity of water. She values mindfulness, meditation and connection with nature. There is a buddhist statue in center representing a mother and child, a woman working on the left hand side with child and a confident happy looking woman to the right. She mentioned that the theme of motherhood and a woman who is caring is a powerful one for her.'}, 'alt': 'Kate Collage', naturalWidth: 3024, naturalHeight: 2573},
    { 'image': 'Kari_collage.jpg', 'dataset': {'description': 'This collage was from a friend who works in fashion design. She said she had a great time making it as it revealed new insights for her. As she was making it she said she felt the theme of black and white versus color juxtaposed represented her need of structure versus freedom in her life. She felt that she\'s a highly creative person, but also needs some level of oder in order to keep things organized, and to feel secure. She expressed this feeling through the various contrasting colors and motifs.'}, 'alt': 'Kari Collage', naturalWidth: 3024, naturalHeight: 4032 },
    { 'image': 'Isaac_collage.jpg', 'dataset': {'description': 'This piece was made by my friend who said he loves finding interesting faces and figures. He noted how many of the images he chose also have pop culture references or are funny in some way. He works as a writer in Hollywood so he connected this to his career and his passion for telling engaging stories. '}, 'alt': 'Isaac Collage', naturalWidth: 3024, naturalHeight: 4032},
    { 'image': 'treasure_map_collage.jpg', 'dataset': {'description': 'This was a treasure map I made depicting the journey I\'ve been on during my masters degree studies. There are many paths and pitfalls along the way, as well as positive experiences. The path ends with gratitude and symbols of being transformed.'}, 'alt': 'Treasure Map Collage', naturalWidth: 2892, naturalHeight: 3024},
    { 'image': 'art_party_1.jpg', 'dataset': {'description': 'Group Art Party'}, 'alt': 'Art Party 1', naturalWidth: 1863, naturalHeight: 2007},
    { 'image': 'art_party_2.jpg', 'dataset': {'description': 'Group Art Party'}, 'alt': 'Art Party 2', naturalWidth: 3024, naturalHeight: 4032},
    { 'image': 'art_party_3.jpg', 'dataset': {'description': 'Group Art Party'}, 'alt': 'Art Party 3', 'naturalWidth': 3024, 'naturalHeight': 4032},
    { 'image': 'art_party_4.jpg', 'dataset': {'description': 'Group Art Party'}, 'alt': 'Art Party 4', 'naturalWidth': 3024, 'naturalHeight': 4032},
    { 'image': 'art_party_7.jpg', 'dataset': {'description': 'Group Art Party'}, 'alt': 'Art Party 5', 'naturalWidth': 4032, 'naturalHeight': 3024}
];

// Function to add 'src' property to each image object
export const imagesWithSrc = (() => {
    return images.map(image => ({
        ...image,
        src: `${image_path}${image.image}`
    }));
})(images);

export const imagesRandomized = [...imagesWithSrc].sort(randomSortOrder);

export default images;