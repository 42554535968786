import React, { Component } from 'react';

class Groups extends Component {
    render () {
        let file_path = "/images/art/groups/";
        let image_file = "IMG_4814.jpg";
        let image_file_path = file_path + image_file;
        return (
            <div className="text-section workshop-text-section">
                <h5 className="text-section-title">Arts and Garden Groups</h5>
                <img src={image_file_path} alt="" id="workshop_image" className="workshops-garden-group-image"></img>
                <p>
                    As a counselor, I work with the homeless population,
                    helping individuals struggling with mental health and
                    substance abuse issues to recover. Our facility is a
                    medium-term program lasting 1-3 months, where we receive clients
                    referred from psychiatric hospitals. At our facility,
                    clients work on regaining a sense of stability, building
                    daily routines and creating healthy habits as they prepare
                    to find long-term housing solutions. In my role, along with
                    monitoring client safety, I faciliate weekly arts and garden
                    groups where the clients come together to foster their
                    creativity, connect with nature, and grow in their confidence.
                </p>
                <div style={{clear: "both"}}></div>
            </div>
        );
    }
}

export default Groups 