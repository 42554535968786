import React, { Component } from 'react';

class AwakeningIntuion extends Component {
    handleImageClick = () => {
        const document_file_path = "/documents/AwakeningIntuitionActivityRyanHenaoInstructorsCopy.pdf"; // Replace with the actual document path
        window.open(document_file_path, '_blank', 'noopener,noreferrer');
    }
    render() {
        let file_path = "/documents/";
        let workshop_file_image = "AwakeningIntuitionActivityRyanHenaoInstructorsCopyThumbnail_4_3.jpg";
        let workshop_download_image = file_path + workshop_file_image;
        return (
            <div className="text-section workshop-text-section">
                <h5 className="text-section-title">Awakening Intuition Workshop</h5>
                <img src={workshop_download_image} alt="" id="workshop_image" className="thumbnail-image awakening-intuition-image" onClick={this.handleImageClick}></img>
                <p>
                    The participants spent time looking through magazines, used
                    art books, and other picture books I gathered, cutting out
                    images that resonated with them in some way. They were
                    instructed to maintain a mindful and inquisitive nature
                    throughout the process as they observed which images they
                    chose. Some things to consider were, how each image made
                    them feel, what associations arose, and which senses they
                    observed them through. After collaging participants were
                    invited to see what meaning their art work revealed to them.
                    Similar to Jung's sand-play, the imaginative process of
                    collaging various images together on paper helps to bring to
                    the surface unconscious feelings, beliefs, goals and
                    motivations; the context of a playful, allows for all of
                    this to be done in a safe and fun manner.
                </p>
                <div style={{clear: "both"}}></div>
            </div>
        );
    }
}

export default AwakeningIntuion 