import React, { Component } from 'react';
import BooklistHeader from './BooklistHeader.js'
import FullBooklist from './FullBooklist.js'

class Booklist extends Component {
    render() {
        return (
            <div className="booklist-page">
                <BooklistHeader/>
                <FullBooklist/>
            </div>
        );
    };
};

export default Booklist;