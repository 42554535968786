import React, { Component } from 'react';

class WorkshopsTagline extends Component {
    render () {
        return (
            <div className="tagline workshops-tagline">
                <p>Exploring Intuition</p>
                <p>Discovering Meaning</p>
                <p>Transforming Together</p>
            </div>
        );
    }
}

export default WorkshopsTagline