import React, { Component } from 'react';

class EssaysHeader extends Component {
  render() {
    return (
        <div className="page-header essays-header">
          <h3>Essays</h3>
        </div>
    );
  }
}

export default EssaysHeader;
