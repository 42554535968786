import React, { Component } from 'react';
import ContactHeader from './ContactHeader';

class Contact extends Component {
    render () {
        return (
            <div className="contact-page">
                <ContactHeader/>
                <div>
                    <h6>Email: ryanbhenao@gmail.com</h6>
                </div>
                <div>
                    <h6>LinkedIn: https://www.linkedin.com/in/ryan-henao/</h6>
                </div>
                <div>
                    <h6>GitHub: https://github.com/rbhenao</h6>
                </div>
            </div>
        );
    }
}

export default Contact;