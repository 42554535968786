import React, { Component, useRef } from 'react';
import LazyIframe from '../../Apps/LazyIFrame/LazyIFrame.js';
import videos from '../../../data/videos.js';

class VideosContent extends Component {
    
    renderFrames = () => videos.map((video, index) => ( <LazyIframe key={index} title={video.title} url={video.src}/> )); 

    render () {
        return (
            <div className="more-page-body videos-content">
                {this.renderFrames()}
            </div>
        );
    }
}

export default VideosContent;