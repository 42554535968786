import React, { Component } from 'react';

import books from '../../../data/books.js'
import BooklistSection from './BooklistSection.js';

class FullBooklist extends Component {
    render() {
        return (
            <div className="full-book-list">
                {books.map((section, sectionIndex) =>
                    <div className="book-section" key={sectionIndex}>
                        <h4 className="book-section-header">{section.section}</h4>
                        <BooklistSection section={section} sectionIndex={sectionIndex}/>
                        <hr />
                    </div>
                )}
            </div>
        );
    }
}

export default FullBooklist;
