import React, { Component } from 'react';

class AboutHeader extends Component {
  render() {
    return (
        <div className="page-header about-header">
          <h3>About</h3>
        </div>
    );
  }
}

export default AboutHeader;